import { apiMyCollect } from '@/api/api.js'
export default {
  components: {},
  data() {
    return {
      resultData: [],
      page: 1,
      pageNum: 10,
      loading: false,
      noMore: false
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.getMyCollect()
    },
    jump(data) {
      if (data.type == 'practice') {
        this.$router.push({
          name: 'Exercises',
          query: { clid: data.clid, practiceId: data.paramerid }
        })
        // this.$router.push({name:'GrePracticeDetail',query:{clid:data.clid,type:data.tag_name,id:data.paramerid}})
      }
      if (data.type == 'gremat') {
        this.$router.push({
          name: 'Exercises',
          query: { clid: data.clid, greId: data.paramerid }
        })
        // this.$router.push({name:'GreDetail',query:{clid:data.clid,type:data.tag_name,id:data.paramerid}})
      }
    },
    getMyCollect() {
      // this.loading = true
      let paramsBase = {
        page: this.page,
        pageNum: this.pageNum
      }
      apiMyCollect(paramsBase).then((res) => {
        let dataValue = res.data.list
        if (dataValue.length != 0) {
          setTimeout(() => {
            dataValue.forEach((element) => {
              this.resultData.push(element)
            })
          }, 500)
        } else {
          this.loading = false
          this.noMore = true
        }
      })
    },
    load() {
      this.page++
      this.getMyCollect()
    }
  }
}
